import * as React from 'react';
import { useState, useEffect } from "react";
import axios from 'axios';
import Sidebar from '../components/sidebar'
import Footer from '../components/footer'
import Head from '../components/head'


export default function Main() {

 
  const [name,setName]=useState('')
  const [review,setReview]=useState('')
  const [des,setDes]=useState('')
  const [star,setStar]=useState('')
  const [extra,setExtra]=useState('')
  const [img,setImage]=useState('https://etihadtravels.co.uk/images/hotel/')

  const [pr,setPr]=useState('')
  const [extra2,setExtra2]=useState('')

  useEffect(() => {
    get();
  }, []);


  const get =()=> {
    const queryParams = new URLSearchParams(window.location.search)
    const id = queryParams.get("id")
    getbyid(id)
    };
  


  const getbyid = async (id)=> {
    const data = new FormData();
    
    data.append('id',id);



            axios({
              method: 'post',
              url: '/api/hotel/get_by_id.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
               
              setName(s.data[0].name)
              
              setDes(s.data[0].des)
              setExtra(s.data[0].ex)
              setImage(s.data[0].img)
              setStar(s.data[0].star)
              setReview(s.data[0].review)

              setPr(s.data[0].pr)
              setExtra2(s.data[0].ex2)
             
              
             
                
               
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const getDb = async ()=> {
    const queryParams = new URLSearchParams(window.location.search)
    const id = queryParams.get("id")

    const data = new FormData();  

    data.append('id',id);
    data.append('name',name);
    data.append('review',review);
    data.append('des',des);
    data.append('star',star);
    data.append('ex',extra);
    data.append('img',img);

    data.append('ex2',extra2);
    data.append('pr',pr);

            axios({
              method: 'post',
              url: '/api/hotel/update.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
               
                console.log("run")
                if(s.data[0].msg=="pass")
                {
                  alert("Updated.")

                }else{
                  alert("Not update try again !")
                }
               
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };


  return (
    <>
     <div className="main-wrapper">
        <Sidebar />
        <div className="page-wrapper">
       
       <Head />
       <div>
    

{/* 2 */}
        <div className="page-content">
        <div className="row">
    <div className="col-md-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="mb-4">Update Hotel </h4>
         
            <div className="row">
            
              <div className="col-lg-6 mb-3">
                <label htmlFor="fullname" className="form-label">
                  
                  Hotel Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fullname"
                  autoComplete="off"
                  placeholder=""
                  value={name} onChange={(v)=>{ setName(v.target.value)}}
                />
              </div>
              <div className="col-lg-1 mb-3">
                <label htmlFor="email" className="form-label">
              Review
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  autoComplete="off"
                  placeholder=""
                  value={review} onChange={(v)=>{ setReview(v.target.value)}}
                />
              </div>

              <div className="col-lg-1 mb-3">
                <label htmlFor="fullname" className="form-label">
                  
                  Price
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fullname"
                  autoComplete="off"
                  placeholder=""
                  value={pr} onChange={(v)=>{ setPr(v.target.value)}}
                />
              </div>
              <div className="col-lg-4 mb-3">
                <label htmlFor="email" className="form-label">
              Extra 2
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  autoComplete="off"
                  placeholder=""
                  value={extra2} onChange={(v)=>{ setExtra2(v.target.value)}}
                />
              </div>
              
            
              <div className="col-lg-12 mb-3">
                <label htmlFor="password" className="form-label">
                  
                 Description
                </label>
                <textarea
                  className="form-control"
                  onChange={(v)=>{ setDes(v.target.value)}}
                  value={des}
                ></textarea>
              </div>

              <div className="col-lg-6 mb-3">
              <label htmlFor="password" className="form-label">Star of Hotel</label>
              <select  value={star} onChange={(v)=>{ setStar(v.target.value)}} className='form-control'>
                <option></option>
                <option>star</option>
                <option>1-star</option>
                <option>2-star</option>
                <option>3-star</option>
                <option>4-star</option>
                <option>5-star</option>
                <option>7-star</option>
              </select>
              </div>

              <div className="col-lg-6 mb-3">
              <label htmlFor="password" className="form-label">Select Makkah / Madinah</label>
              <select disabled  value={extra} onChange={(v)=>{ setExtra(v.target.value)}} className='form-control'>
                <option></option>
                <option>Makkah</option>
                <option>Madinah</option>
             
             
              </select>
              </div>

              <div className="col-lg-12 mb-3">
                <label htmlFor="password" className="form-label">
                    Image Path / URL
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="password"
                  autoComplete="off"
                  placeholder=""
                  value={img} onChange={(v)=>{ setImage(v.target.value)}}
                />
              </div>
   

             
              <div>
                <button  className="btn btn-primary" onClick={()=>{
                  getDb();
                }} >
                  <i className="link-icon" data-feather="plus" /> Update Hotel</button>
              </div>
            </div>
         
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
     
  
       
        <Footer/>
       
      </div>
    </div>
  </>

  )
}
