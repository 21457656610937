import React, { useState ,useEffect} from 'react';
import Sidebar from './components/sidebar'
import Footer from './components/footer'
import Head from './components/head'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's CSS for styling


export default function Main() {

  const [phoneNumber, setPhoneNumber] = useState('');
  const [result, setResult] = useState(null);

  const checkNumber = async () => {
      try {
          const response = await fetch('/api/page.js', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ phoneNumber }),
          });

          const data = await response.json();
          if (response.ok) {
              setResult(data.isRegistered ? 'Registered on WhatsApp' : 'Not registered on WhatsApp');
          } else {
              setResult(`Error: ${data.error}`);
          }
      } catch (err) {
          setResult('Something went wrong.');
      }
  }




  const [title, setTitle] = useState('');
  const [des, setDes] = useState('');
  const [keyword, setKeyword] = useState('');
  const [slug, setSlug] = useState('');
  const [img, setImg] = useState(null);
  const [status, setStatus] = useState('draft');
  const [content, setContent] = useState('');
  const [preview, setPreview] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImg(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
     <div className="main-wrapper">
        <Sidebar />
        <div className="page-wrapper">
       
       <Head />
       
       <div className="page-content">
        {/* start wp*/}
        <div>
            <input
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Enter phone number"
            />
            <button onClick={checkNumber}>Check WhatsApp</button>
            {result && <p>{result}</p>}
        </div>

        {/* END wp*/}

    <div className='row'>
      <div className='col-12 card'>
      <div className="container mt-5">
      <h2>New Blog</h2>
      <br/>
      <div className='row'>
        <div className='col-4'>
        <div className="mb-3 ">
          <label htmlFor="title" className="form-label">Title</label>
          <input
            type="text"
            className="form-control"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        </div>

        <div className='col-4'>
        <div className="mb-3">
          <label htmlFor="keyword" className="form-label">Keyword</label>
          <input
            type="text"
            className="form-control"
            id="keyword"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            required
          />
        </div>

        </div>

        <div className='col-4'>
        <div className="mb-3">
          <label htmlFor="slug" className="form-label">Slug</label>
          <input
            type="text"
            className="form-control"
            id="slug"
            value={slug}
            onChange={(e) => setSlug(e.target.value)}
            required
          />
        </div>

        </div>


        <div className='col-4'>
        <div className="mb-3">
          <label htmlFor="img" className="form-label">Upload Image</label>
          <input
            type="file"
            className="form-control"
            id="img"
            accept="image/*"
            onChange={handleImageChange}
            required
          />
          {preview && <img src={preview} style={{width:"300px",height:"200px"}} alt="Preview" className="img-thumbnail mt-3" />}
        </div>
        </div>
        <div className='col-4'>
        <div className="mb-3">
          <label htmlFor="status" className="form-label">Status</label>
          <select
            className="form-control"
            id="status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            required
          >
            <option value="draft">Draft</option>
            <option value="published">Published</option>
          </select>
        </div>
        </div>
        <div className='col-4'>
        <div className="mb-3">
          <label htmlFor="des" className="form-label">Description</label>
          <textarea
            className="form-control"
            id="des"
            value={des}
            onChange={(e) => setDes(e.target.value)}
            required
          />
        </div>
        </div>
        <div className='col-12'>
        <div className="mb-3">
        <ReactQuill
        theme="snow"
        value={content}
        onChange={(e) => setContent(e)}
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, 4, false] }], // Headers
            [{ font: [] }], // Font family
            [{ size: [] }], // Font size
            ['bold', 'italic', 'underline', 'strike', 'blockquote'], // Basic styling
            [
              { color: [] }, 
              { background: [] }
            ], // Text color and background color
            [{ script: 'sub' }, { script: 'super' }], // Subscript / Superscript
            [{ list: 'ordered' }, { list: 'bullet' }], // Lists
            [{ align: [] }], // Text alignment
            ['link', 'image', 'video'], // Media links
            ['clean'], // Clear formatting
          ],
        }}
        formats={[
          'header', 'font', 'size',
          'bold', 'italic', 'underline', 'strike', 'blockquote',
          'color', 'background',
          'script', 'list', 'bullet',
          'align', 'link', 'image', 'video',
        ]}
        style={{ height: '800px', width: '1200px' }}
      />
        </div>
        </div>
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <div className='col-12'>
      <button type="submit" className="btn btn-primary">Apply</button>
      </div>
   

  



  
 





        
      
    </div>
      </div>
     

    </div>

        {/* row */}

      </div>


  
       
        <Footer/>
       
      </div>
    </div>
  </>

  )
}
