import React from 'react'
import logo from '../img/logo.png';

export default function sidebar() {
  return (
    <>
    <nav className="sidebar">
      <div className="sidebar-header">
        <a href="../index.html" className="sidebar-brand">
          <img src={logo} alt="logo" className="w-75" />
        </a>
        <div className="sidebar-toggler not-active">
          <span />
          <span />
          <span />
          
        </div>
      </div>
      <div className="sidebar-body">
        <ul className="nav">
          <li className="nav-item active">
            <a href="main" className="nav-link">
              <i className="link-icon" data-feather="box" />
              <span className="link-title">Dashboard</span>
            </a>
          </li>

          <li className="nav-item" ><h4>Seo</h4></li>
          <li className="nav-item active">
            <a href="addseo" className="nav-link">
              <i className="link-icon" data-feather="box" />
              <span className="link-title">New Seo Page</span>
            </a>
          </li>
          <li className="nav-item active">
            <a href="viewseo" className="nav-link">
              <i className="link-icon" data-feather="box" />
              <span className="link-title"> View SEO</span>
            </a>
          </li>

     

          <li className="nav-item" ><h4>Hotel</h4></li>


          <li className="nav-item active">
            <a href="addhotel" className="nav-link">
              <i className="link-icon" data-feather="box" />
              <span className="link-title"> New Hotel</span>
            </a>
          </li>
          <li className="nav-item active">
            <a href="viewhotel" className="nav-link">
              <i className="link-icon" data-feather="box" />
              <span className="link-title"> Hotel List</span>
            </a>
          </li>

          {/* Packages */}
          <li className="nav-item" ><h4>Package</h4></li>


          <li className="nav-item active">
            <a href="addpackage" className="nav-link">
              <i className="link-icon" data-feather="box" />
              <span className="link-title"> New Packages</span>
            </a>
          </li>
          <li className="nav-item active">
            <a href="listpackage" className="nav-link">
              <i className="link-icon" data-feather="box" />
              <span className="link-title"> Package List</span>
            </a>
          </li>
          {/* Logout */}

          <li className="nav-item">
            <a href='login'  className="nav-link" 
            onClick={()=>{
                    localStorage.clear()
                    
                    }}>
              <i className="link-icon" data-feather="log-out" />
              <span className="link-title">Logout</span>
            </a>
          </li>

        </ul>
      </div>
    </nav>
    </>
  )
}
