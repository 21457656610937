import * as React from 'react';
import { useState, useEffect } from "react";
import axios from 'axios';
import Sidebar from '../components/sidebar'
import Footer from '../components/footer'
import Head from '../components/head'


export default function Main() {


    const [month_id_show,setmonth_show]=useState('')
    const [id,setID]=useState('')



  const [name,setName]=useState('')
  const [des,setDes]=useState('')
  const [makkah_nights,setmakkah_nights]=useState('')
  const [madinah_nights,setmadinah_nights]=useState('')
  const [extra_nights,setextra_nights]=useState('')
  const [total_nights,settotal_nights]=useState('')
  const [package_type_id,setpackage_type_id]=useState('')
  const [hotel_makkah_id,sethotel_makkah_id]=useState('')
  const [hotel_madinah_id,sethotel_madinah_id]=useState('')
  const [hotel_extra_id,sethotel_extra_id]=useState('')
  const [flight,setflight]=useState('')
  const [visa,setvisa]=useState('')
  const [transport,settransport]=useState('')
  const [review,setreview]=useState('')
  const [month_id,setmonth_id]=useState('')
  const [extra_country,setextra_country]=useState('')
  const [img,setImage]=useState('')
  const [extra,setExtra]=useState('')
  const [viewby,setviewby]=useState('')
  const [pr,setpr]=useState('')
  const [ex2,setex2]=useState('')
  const [alt,setalt]=useState('')
  const [img_title,setimg_t]=useState('')

  const [hotel,sethotel]=useState([])
  const [allmonth,setallmonth]=useState([])
  const [type_cat,settypecat]=useState([])

  useEffect(() => {
    getAllseo();
    getAllMonth();
    getAllPAck();
    get();
  }, []);

  const get =()=> {
    const queryParams = new URLSearchParams(window.location.search)
    const id = queryParams.get("id")
    const m = queryParams.get("m")
    setmonth_show(m)
    setID(id)
    getbyid(id)
    };
  

  const getbyid = async (id)=> {
    const data = new FormData();
    
    data.append('id',id);



            axios({
              method: 'post',
              url: '/api/package/get_id.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {

     


              setName(s.data[0].name) 
              setDes(s.data[0].des)
              setmakkah_nights(s.data[0].makkah_nights)
              setmadinah_nights(s.data[0].madinah_nights)
              setextra_nights(s.data[0].extra_nights)
              settotal_nights(s.data[0].total_nights)
              setpackage_type_id(s.data[0].package_type_id)
              sethotel_makkah_id(s.data[0].hotel_makkah_id)
              sethotel_madinah_id(s.data[0].hotel_madinah_id)
              sethotel_extra_id(s.data[0].hotel_extra_id)
              setflight(s.data[0].flight)
              setvisa(s.data[0].visa)
              settransport(s.data[0].transport)
              setreview(s.data[0].review)
              setmonth_id(s.data[0].month_id)
              setextra_country(s.data[0].extra_country)
              setImage(s.data[0].img)
              setExtra(s.data[0].extra)
              setviewby(s.data[0].viewby)
              setpr(s.data[0].price)
              
              setex2(s.data[0].ex2)

              setalt(s.data[0].alt)

              setimg_t(s.data[0].img_des)

         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };




  const getAllseo = async ()=> {
    const data = new FormData();
    
    data.append('name',"nme");


            axios({
              method: 'post',
              url: '/api/hotel/view.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
               
                
            
                sethotel(s.data)
                console.log(s.data)
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const getAllMonth = async ()=> {
    const data = new FormData();
    
    data.append('name',"nme");


            axios({
              method: 'post',
              url: '/api/package/all_month_type.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
               
                
            
                setallmonth(s.data)
                console.log(s.data)
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const getAllPAck = async ()=> {
    const data = new FormData();
    
    data.append('name',"nme");


            axios({
              method: 'post',
              url: '/api/package/all_package_type.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
               
                
            
                settypecat(s.data)
                console.log(s.data)
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };
       
        
  const getDb = async (id)=> {
    const data = new FormData();  
    data.append('id',id);
    data.append('name',name);
    data.append('des',des);
    data.append('makkah_nights',makkah_nights);
    data.append('madinah_nights',madinah_nights);
    data.append('extra_nights',extra_nights);
    data.append('total_nights',total_nights);
    data.append('package_type_id',package_type_id);
    data.append('hotel_makkah_id',hotel_makkah_id);
    data.append('hotel_madinah_id',hotel_madinah_id);
    data.append('hotel_extra_id',hotel_extra_id);
    data.append('flight',flight);
    data.append('visa',visa);
    data.append('transport',transport);
    data.append('review',review);
    data.append('month_id',month_id);
    data.append('extra_country',extra_country);
    data.append('img',img);
    data.append('extra',extra);
    data.append('viewby',viewby);
    data.append('pr',pr);
    data.append('ex2',ex2);

    data.append('alt',alt);
    data.append('des_img',img_title);
    




            axios({
              method: 'post',
              url: '/api/package/update.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
               
                console.log("Update ==> ",s.data)

                if(s.data[0].msg=="already")
                {
                  alert("already")

                }else if(s.data[0].msg=="fail")
                  {
                    alert("Fail here")
                  }else{
                    alert("Saved here")
                  }
               
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };


  return (
    <>
     <div className="main-wrapper">
        <Sidebar />
        <div className="page-wrapper">
       
       <Head />
       <div>
    

{/* 2 */}
        <div className="page-content">
    <div className="row">
    <div className="col-md-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="mb-4">Update Packages {month_id_show} - {id} </h4>
        
            <div className="row">              
               <div className="col-lg-6 mb-3">
                <label htmlFor="fullname" className="form-label">
                  Package Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fullname"
                  autoComplete="off"
                  placeholder=""
                  disabled
                  value={name} onChange={(v)=>{ setName(v.target.value)}}
                />
              </div>


              <div className="col-lg-1 mb-3">
                <label htmlFor="email" className="form-label">
              Review
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  autoComplete="off"
                  placeholder=""
                  value={review} onChange={(v)=>{ setreview(v.target.value)}}
                />
              </div>

              <div className="col-lg-1 mb-3">
                <label htmlFor="fullname" className="form-label">
                  
                  Price
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fullname"
                  autoComplete="off"
                  placeholder=""
                  value={pr} onChange={(v)=>{ setpr(v.target.value)}}
                />
              </div>
              
              
                 <div className="col-lg-1 mb-3">
                <label htmlFor="email" className="form-label">
                Mak Nights
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  autoComplete="off"
                  placeholder=""
                  disabled
                  value={makkah_nights} onChange={(v)=>{ setmakkah_nights(v.target.value)}}
                />
              </div>

              <div className="col-lg-1 mb-3">
                <label htmlFor="email" className="form-label">
                Mad Nights
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  autoComplete="off"
                  placeholder=""
                  disabled
                  value={madinah_nights} onChange={(v)=>{ setmadinah_nights(v.target.value)}}
                />
              </div>
              
              

             
              <div className="col-lg-1 mb-3">
                <label htmlFor="email" className="form-label">
                Extra Nights
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  autoComplete="off"
                  placeholder=""
                  value={extra_nights} onChange={(v)=>{ setextra_nights(v.target.value)}}
                />
              </div>
             
              
              <div className="col-lg-1 mb-3">
                <label htmlFor="email" className="form-label">
                Total Nights
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  autoComplete="off"
                  placeholder=""
                  disabled
                  value={total_nights} onChange={(v)=>{ settotal_nights(v.target.value)}}
                />
              </div>
             
              
            
              <div className="col-lg-12 mb-3">
                <label htmlFor="password" className="form-label">
                  
                 Description
                </label>
                <textarea
                  className="form-control"
                  value={des}
                  onChange={(v)=>{ setDes(v.target.value)}}
                ></textarea>
              </div>
              

              <div className="col-lg-12 mb-3">
                <label htmlFor="password" className="form-label">
                    Image Path / URL
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="password"
                  autoComplete="off"
                  placeholder=""
                  disabled
                  value={img} onChange={(v)=>{ setImage(v.target.value)}}
                />
              </div>

              <div className="col-lg-6 mb-3">
                <label htmlFor="password" className="form-label">
                    Img Alt
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="password"
                  autoComplete="off"
                  placeholder=""
                  value={alt} onChange={(v)=>{ setalt(v.target.value)}}
                />
              </div>

              <div className="col-lg-6 mb-3">
                <label htmlFor="password" className="form-label">
                    Image Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="password"
                  autoComplete="off"
                  placeholder=""
                  value={img_title} onChange={(v)=>{ setimg_t(v.target.value)}}
                />
              </div>


   <div className="col-lg-4 mb-3">
              <label htmlFor="password" className="form-label">Makkah Hotel</label>
              <select value={hotel_makkah_id} onChange={(v)=>{ sethotel_makkah_id(v.target.value)}} className='form-control'>
              <option></option>
                {
                    hotel.map((v,i)=>(
                    
                        v.ex=="Makkah"?<option value={v.id}>{v.star} -- {v.name}</option>:""
                    
                        
                    ))
                }
                
              
              </select>
              </div>
              
              <div className="col-lg-4 mb-3">
              <label htmlFor="password" className="form-label">Madinah Hotel</label>
              <select value={hotel_madinah_id} onChange={(v)=>{ sethotel_madinah_id(v.target.value)}} className='form-control'>
                <option></option>
                {
                    hotel.map((v,i)=>(
                      v.ex=="Madinah"?<option value={v.id}>{v.star} -- {v.name}</option>:""    
                    ))
                }
                
              
              </select>
              </div>
             
             




             
              <div className="col-lg-4 mb-3">
              <label htmlFor="password" className="form-label">Extra Hotel</label>
              <select value={hotel_extra_id} onChange={(v)=>{ sethotel_extra_id(v.target.value)}} className='form-control'>
              <option></option>
                {
                    hotel.map((v,i)=>(
                    
                        v.ex=="Madinah"?"": v.ex=="Makkah"?"":<option value={v.id}>{v.name}</option>
                    
                        
                    ))
                }
                
              
              </select>
              </div>

              {/* flight 1*/}
              <div className="col-lg-4 mb-3">
              <label htmlFor="password" className="form-label">Flight</label>
              <select value={flight} onChange={(v)=>{ setflight(v.target.value)}} className='form-control'>
               <option></option>
               <option value={1}>yes</option>
               <option value={0}>no</option>
                
              
              </select>
              </div>
              {/* end 1 */}
              
              {/* visa 2*/}
              <div className="col-lg-4 mb-3">
              <label htmlFor="password" className="form-label">Visa</label>
              <select value={visa} onChange={(v)=>{ setvisa(v.target.value)}} className='form-control'>
               <option></option>
               <option value={1}>yes</option>
               <option value={0}>no</option>
                
              
              </select>
              </div>
              {/* end 2 */}
              
              {/* transport 3*/}
              <div className="col-lg-4 mb-3">
              <label htmlFor="password" className="form-label">Transport</label>
              <select value={transport} onChange={(v)=>{ settransport(v.target.value)}} className='form-control'>
               <option></option>
               <option value={1}>yes</option>
               <option value={0}>no</option>
               </select>
              </div>
              {/* end 3 */}

              {/* month 4*/}
              <div className="col-lg-3 mb-3">
              <label htmlFor="password" className="form-label">Month {month_id} </label>
              <select disabled value={month_id} onChange={(v)=>{ setmonth_id(v.target.value)}} className='form-control'>
               <option></option>

               {
                allmonth.map((v)=>(
                  <option value={v.slug}>{v.name}</option>
                ))
               }
               
              
               </select>
              </div>
              {/* end 4 */}

            
              

           
                 <div className="col-lg-3 mb-3">
                <label htmlFor="password" className="form-label">
                    Extra Country
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="password"
                  autoComplete="off"
                  placeholder=""
                  value={extra_country} onChange={(v)=>{ setextra_country(v.target.value)}}
                />
              </div>

              <div className="col-lg-3 mb-3">
                <label htmlFor="password" className="form-label">
                   view By
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="password"
                  autoComplete="off"
                  placeholder=""
                  disabled

                  value={viewby} onChange={(v)=>{ setviewby(v.target.value)}}
                />
              </div>
              <div className="col-lg-12 mb-3">
                <label htmlFor="password" className="form-label">
                    Ex2
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="password"
                  autoComplete="off"
                  placeholder=""
                  value={ex2} onChange={(v)=>{ setex2(v.target.value)}}
                />
              </div>
              <div className="col-lg-3 mb-3">
                <label htmlFor="password" className="form-label">
                 Slug / URL
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="password"
                  autoComplete="off"
                  placeholder=""
                  disabled
                  value={extra} onChange={(v)=>{ setExtra(v.target.value)}}
                />
              </div>
              
   

             
              <div>
                <button  className="btn btn-primary" onClick={()=>{
                  getDb(id);
                }} >
                  <i className="link-icon" data-feather="plus" /> New Package</button>
              </div>
            </div>
         
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
     
  
       
        <Footer/>
       
      </div>
    </div>
  </>

  )
}
